import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SEO from '../components/seo';
import Container from '../components/container/container';
import { PageHeading } from '../components/page-heading/page-heading';
import LayoutContent from '../components/layout/layout-content';
import SectionImage from '../components/container/section-image';
import TwoColumnSection from '../components/container/two-column-section';
import { IconList } from '../components/iconlist/icon-list';
import Vector1 from '../images/services/break-lines-svg/vector_1.svg';
import Vector2 from '../images/services/break-lines-svg/vector_2.svg';
import Vector3 from '../images/services/break-lines-svg/vector_3.svg';
import Vector4 from '../images/services/break-lines-svg/vector_4.svg';
import Vector5 from '../images/services/break-lines-svg/vector_5.svg';
import Vector6 from '../images/services/break-lines-svg/vector_6.svg';
import { useMedia } from '../hooks/useMedia';
import Img from 'gatsby-image';
import Cta1to1Meeting from '../components/container/cta-1to1-meeting';
import { InternalLinkButton } from '../components/button/internal-link-button';
import TrailAnimation from '../components/animate/TrailAnimation';
import styles from '../styles/leistungen.module.scss';

const ServicePage = () => {
  const {
    imgServiceMeeting,
    imgServicePenPaper,
    imgServiceDesktop,
    imgServiceMan,
    imgServiceMonitor,
    imgServiceGirls,
    imgServiceCollab,
    imgMap,
    iconGatsby,
    iconReact,
    iconSanity,
    iconSass,
    iconAmazonS3,
    iconAmazonCloud,
    iconLambda,
    iconGraphql,
    iconGateway,
    iconLegendEdge,
    iconLegendMultiple,
    iconLegendRegional,
  } = useStaticQuery(
    graphql`
      query {
        imgServiceMeeting: file(relativePath: { eq: "services/service_meeting.png" }) {
          childImageSharp {
            fluid(maxWidth: 540, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        imgServicePenPaper: file(relativePath: { eq: "services/service_pen_paper.png" }) {
          childImageSharp {
            fluid(maxWidth: 540, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        imgServiceDesktop: file(relativePath: { eq: "services/service_desktop.png" }) {
          childImageSharp {
            fluid(maxWidth: 729, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        imgServiceMan: file(relativePath: { eq: "services/service_man.png" }) {
          childImageSharp {
            fluid(maxWidth: 540, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        imgServiceMonitor: file(relativePath: { eq: "services/service_monitor.png" }) {
          childImageSharp {
            fluid(maxWidth: 540, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        imgServiceGirls: file(relativePath: { eq: "services/service_girls.png" }) {
          childImageSharp {
            fluid(maxWidth: 540, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        imgServiceCollab: file(relativePath: { eq: "services/service_collab.png" }) {
          childImageSharp {
            fluid(maxWidth: 540, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        imgMap: file(relativePath: { eq: "services/service_map.png" }) {
          childImageSharp {
            fluid(maxWidth: 1060, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        iconGatsby: file(relativePath: { eq: "icons/services-icons/icon-gatsby.svg" }) {
          publicURL
          extension
        }
        iconReact: file(relativePath: { eq: "icons/services-icons/icon-react.svg" }) {
          publicURL
          extension
        }
        iconSanity: file(relativePath: { eq: "icons/services-icons/icon-sanityio.svg" }) {
          publicURL
          extension
        }
        iconSass: file(relativePath: { eq: "icons/services-icons/icon-sass.svg" }) {
          publicURL
          extension
        }
        iconAmazonS3: file(relativePath: { eq: "icons/services-icons/icon-amazon-s3.svg" }) {
          publicURL
          extension
        }
        iconAmazonCloud: file(relativePath: { eq: "icons/services-icons/icon-amazon-cloudfront.svg" }) {
          publicURL
          extension
        }
        iconLambda: file(relativePath: { eq: "icons/services-icons/icon-amazon-lambda.svg" }) {
          publicURL
          extension
        }
        iconGraphql: file(relativePath: { eq: "icons/services-icons/icon-graphql.svg" }) {
          publicURL
          extension
        }
        iconGateway: file(relativePath: { eq: "icons/services-icons/icon-amazon-api-gateway.svg" }) {
          publicURL
          extension
        }
        iconLegendEdge: file(relativePath: { eq: "icons/services-icons/icon-legend-edge.svg" }) {
          publicURL
          extension
        }
        iconLegendMultiple: file(relativePath: { eq: "icons/services-icons/icon-legend-multiple.svg" }) {
          publicURL
          extension
        }
        iconLegendRegional: file(relativePath: { eq: "icons/services-icons/icon-legend-regional.svg" }) {
          publicURL
          extension
        }
      }
    `
  );

  const serviceIcons = [
    { asset: iconGatsby, text: 'Gatsby', altText: 'Gatsby' },
    { asset: iconReact, text: 'React', altText: 'Blog' },
    { asset: iconSanity, text: 'Sanity.io', altText: 'Sanity' },
    { asset: iconSass, text: 'Sass', altText: 'Sass' },
    { asset: iconAmazonS3, text: 'Amazon S3', altText: 'Amazon' },
    { asset: iconAmazonCloud, text: 'Amazon Cloudfront', altText: 'Cloudfront' },
    { asset: iconLambda, text: 'Amazon Lambda', altText: 'Lambda' },
    { asset: iconGraphql, text: 'Graphql', altText: 'Graphql' },
    { asset: iconGateway, text: 'Amazon API Gateway', altText: 'Gateway' },
  ];

  const legends = [
    { asset: iconLegendEdge, text: 'Edge Location', alText: '' },
    { asset: iconLegendMultiple, text: 'Multiple Edge Location', alText: '' },
    { asset: iconLegendRegional, text: 'Regional Edge Location', alText: '' },
  ];

  const isIPad = useMedia(['(max-width: 991px)'], [true], false);

  return (
    <LayoutContent>
      <SEO title="Leistungen - Evelan GmbH" bodyClass={styles.body} />
      <div className={styles.background} />
      <Container>
        {/* <h1 className={styles.h1Underline}>Unsere Leistungen</h1> */}
        <PageHeading>Unsere Leistungen</PageHeading>
      </Container>
      <TwoColumnSection className={styles.section}>
        <TrailAnimation variant="SlideUpHeavy">
          <h2>Beratung</h2>
          <p>
            In einem kostenlosen Erstgespräch bieten wir jedem Interessenten eine unverbindliche Beratung zur Erstellung
            Ihrer Webseite oder einer kompletten Webanwendung an. Wir eruieren ausführlich alle Vorteile und innovativen
            Möglichkeiten, die sich durch von uns eingesetzte JAMstack Web-Technologie ergeben. Gerne klären wir alle
            aufkommenden Fragen - Unser Anspruch ist es Ihren Wünschen gerecht zu werden und alles machbare zu Ihrer
            vollsten Zufriedenheit umzusetzen!
          </p>
          <div>
            <InternalLinkButton to="/projektanfrage/#inquiry-header" className={styles.cta}>
              Jetzt Projektanfrage stellen!
            </InternalLinkButton>
          </div>
        </TrailAnimation>
        {/* <div></div> */}
        <div>
          <SectionImage fluid={imgServiceMeeting.childImageSharp.fluid} alt="" className={styles.sectionImage} />
        </div>
      </TwoColumnSection>
      <Container className={styles.imgBreakline}>
        <div>
          <img src={Vector1} alt="" />
        </div>
      </Container>

      <TwoColumnSection variant="reverse" className={styles.section}>
        <TrailAnimation variant="SlideUpHeavy">
          <h2>Angebot</h2>
          <p>
            Entsprechend unserem ausführlichen Beratungserstgespräch erhalten Sie unverbindlich ein detailliertes
            Angebot für die Erstellung einer Webpräsenz. Darin sind alle aufkommenden Kosten, inklusive eines klaren
            Festpreises transparent beschrieben. Somit erhalten Sie eine optimale Investitionsübersicht und effektive
            Planungssicherheit.
          </p>
        </TrailAnimation>
        <div>
          <SectionImage fluid={imgServicePenPaper.childImageSharp.fluid} alt="" className={styles.sectionImage} />
        </div>
      </TwoColumnSection>
      <Container className={styles.imgBreakline}>
        <div>
          <img src={Vector2} alt="" />
        </div>
      </Container>
      <TwoColumnSection className={styles.section}>
        <TrailAnimation variant="SlideUpHeavy">
          <h2>Design</h2>
          <p>
            Unsere Webdesigner erstellen unabhängig von Content-Details anhand Ihrer Wünsche eine professionelle und
            individuelle Webseite, sobald Sie das Angebot verbindlich annehmen. Dadurch sparen wir Zeit und sind
            zunächst unabhängig von inhaltlichen Details, die wir gemeinsam parallel verfeinern.
          </p>
          <p>
            Sobald die ersten Entwürfe vorliegen, erhalten Sie Zugang zu den Layout-Entwürfen. Hier können Sie sich
            bereits den ersten Eindruck verschaffen und mit uns über mögliche Anpassungen diskutieren. Nutzen Sie hier
            ganz bequem unsere integrierte Kommentarfunktionen, um neben den bekannten Kommunikationsmitteln wie Mail,
            Telefon oder WhatsApp Ihr Feedback zu äußern. Sobald Sie sich für eine Designversion mit allen Details
            entschieden und abgenommen haben, beginnen wir mit der strukturellen Entwicklung Ihrer neuen Webseite. Wir
            halten Sie auch hier ständig auf dem Laufenden!
          </p>
        </TrailAnimation>
        <div>
          <SectionImage fluid={imgServiceDesktop.childImageSharp.fluid} alt="" className={styles.sectionDesktop} />
          <div className={styles.imgBreakline3}>
            <img src={Vector3} alt="" />
          </div>
        </div>
      </TwoColumnSection>
      <TwoColumnSection variant="fix" className={styles.section}>
        <div className={styles.tech}>
          <TrailAnimation variant="SlideUpHeavy">
            <h2> Technische Entwicklung &amp; Umsetzung </h2>
            <h3>Webdesign & Contentpflege</h3>
            <p>
              Ihre von uns entwickelte Webseite wird nach einer ganz neu aufgebauten und sich stetig weiterentwickelten
              “JAMstack” Technologie umgesetzt. Hier wird das Web-Design komplett unabhängig vom Web-Content, also von
              Text- und Bildmaterial aufgesetzt. Somit werden alle Anpassungen deutlich schneller, einfacher und damit
              für Sie auch kosteneffizienter umgesetzt.
            </p>
          </TrailAnimation>
          <TrailAnimation variant="SlideUpHeavy">
            <h3>CMS-Pflege</h3>
            <p>
              Sie haben Zugriff zu allen relevanten Verwaltungs- und Bearbeitungsoberflächen. Die im Hintergrund
              intuitiv nutzbare CMS-Einheit ermöglicht Ihnen eine einfache sowie zeit- und somit kostensparende
              Alternative zu anderen Systemen, die Sie von Agenturen und Neuprogramierungen abhängig gemacht haben.
            </p>
          </TrailAnimation>

          <TrailAnimation variant="SlideUpHeavy">
            <h3>Integration von Webshop und anderen Webanwendungen</h3>
            <p>
              Durch die mögliche Integration eines Shopify® oder digistore®-Systems sind alle üblichen Funktionalitäten
              und Prozesse eines Web-Shops abgebildet. Es lassen sich auch andere Systeme problemlos anbinden, sofern
              diese über eine technische Schnittstelle (API) verfügen. Das könnte Ihr CRM System oder auch ein
              Newsletter Tool sein.
            </p>
          </TrailAnimation>

          <TrailAnimation variant="SlideUpHeavy">
            <h3>Sichere und schnelle Verbindung</h3>
            <p>
              Mit unserer “JAMstack” Technologie stellen wir übrigens auch sicher, dass Ihr Web-Besucher auch bei
              schlechten Mobilfunkverbindungen Ihre Seite schnell und sicher erreicht. Sie profitieren hier von unserem
              kontinuierlichen Service, der alles technisch machbare einsetzt, um die Benutzeroberfläche
              schnellstmöglich zu laden und darzustellen. So werden gerade grafische Elemente, die wir entsprechend für
              Sie aufbereiten bei langsamen Verbindung automatisch in kleinere Formate umgerechnet.
            </p>
            <p>
              Fahren Sie für sich eine Online-Marketing-Aktion, die schlagartig unerwartet hohen Traffic auslösen kann,
              sind unsere Webanwendungen ebenfalls bestens dafür vorbereitet. Dank eingesetzten Technik bleiben auch bei
              sehr hohen und dynamischen Besucherzahlen alle Webseiten in gewohnter Qualität abrufbar. Alle
              Online-Angebote stehen jederzeit zur Verfügung, auch wenn die Konkurrenz vielleicht schon längst
              hoffnungslos überlastet ist.
            </p>
            <p>
              Kommt es an einem Standort oder an einer Serverkomponente zu Ausfällen, werden die Seiten-Besucher direkt
              umgeleitet.
            </p>

            <p>Jeder Aufwand für die Abstimmung und Vorbereitung der Server durch den Hoster entfällt komplett.</p>

            <p>
              Sogar bei Naturkatastrophen und Stromausfällen ist eine Ausfallsicherheit gewährleistet, da die
              cloudbasierte Serverstruktur auf unabhängige Standorte verteilt ist.
            </p>
          </TrailAnimation>
        </div>
        <div>
          <SectionImage fluid={imgServiceMan.childImageSharp.fluid} alt="Entwicklung" className={styles.sectionImage} />
          <div className={styles.techStackWrapper}>
            <p> UNSER TECH STACK </p>
            <IconList items={serviceIcons} />
          </div>
        </div>
      </TwoColumnSection>
      <TwoColumnSection variant="reverse" className={styles.section}>
        <TrailAnimation className={styles.sicherUpdate} variant="SlideUpHeavy">
          {isIPad ? (
            <h3>
              Sicherheits-
              <br />
              zertifikate & Updates
            </h3>
          ) : (
            <h3>Sicherheitszertifikate & Updates</h3>
          )}
          <p>
            Durch automatisierte Updates und anerkannten Sicherheitszertifikaten weisen wir nicht nur höchsten und
            aktuellsten Standards nach, sondern steigern auch indirekt das Ansehen bei allen Webseiten-Besuchern.
            Tägliche Scans sorgen für saubere Webseiten und keine Überraschung durch bösartige Male-Ware. Unser Support
            steht kontinuierlich für Fragen und Optimierungen zur Verfügung und findet immer eine Lösung, die Sie als
            Kunde und auch Ihre Webbesucher zufriedenstellen soll.
          </p>
        </TrailAnimation>
        <div>
          <SectionImage fluid={imgServiceMonitor.childImageSharp.fluid} alt="" className={styles.sectionMonitor} />
        </div>
      </TwoColumnSection>
      <Container className={styles.imgBreakline}>
        <div>
          <img src={Vector4} alt="" />
        </div>
      </Container>
      <div className={styles.secondGradient}>
        <TwoColumnSection className={styles.section}>
          <div className={styles.imgBreakline5}>
            <img src={Vector5} alt="" />
          </div>
          <div>
            <SectionImage
              fluid={imgServiceGirls.childImageSharp.fluid}
              alt=""
              className={styles.sectionTwoImageBelow}
            />
            <TrailAnimation variant="SlideUpHeavy">
              <h2>Vorschau / Abnahme</h2>
              <p>
                Ihre Webseite ist bereit zur Veröffentlichung! Nun gilt es noch an Details zu feilen und letzte
                Optimierungen einzuarbeiten. Vor dem “Go Live” haben sie so noch einmal die Möglichkeit Anpassungen in
                Ruhe offline zu überarbeiten. Diese Vorschau-Funktion besteht übrigens auch weiterhin nach der
                Erstveröffentlichung. Unser Ziel ist es stets, dass Sie zu 100% zufrieden sind!
              </p>
            </TrailAnimation>
          </div>
        </TwoColumnSection>
        <TwoColumnSection className={styles.section}>
          <div className={styles.goLive}>
            <SectionImage
              fluid={imgServiceCollab.childImageSharp.fluid}
              alt=""
              className={styles.sectionTwoImageBelow}
            />

            <TrailAnimation variant="SlideUpHeavy">
              <h2>Go Live</h2>
              <p>
                Erst wenn Sie mit dem Ergebnis zufrieden sind und wir die Zustimmung von Ihnen bekommen haben, geht Ihre
                neue Webseite online. Dabei kümmern wir uns natürlich auch, wenn notwendig um einen Domainumzug.
              </p>
            </TrailAnimation>

            <div className={styles.host}>
              <TrailAnimation variant="SlideUpHeavy">
                <h2> Hosting &amp; Support </h2>
                <p>
                  Auch nach getaner Entwicklungsarbeit werden Sie selbstverständlich bestens von uns versorgt! Neben
                  allgemeinen Support-Anfragen übernehmen wir das komplette Webseiten-Hosting für Sie. Um einen
                  möglichst reibungslosen Ablauf bei allen Belangen rund um Ihre neue Webseite gewährleisten zu können
                  bieten wir Ihnen einen kompetenten Ansprechpartner für den persönlichen Austausch. Dieser funktioniert
                  bei uns gern per E-Mail, Telefon und Videokonferenz oder auch per WhatsApp. Fühlen Sie sich bei uns
                  rundum versorgt, wenn es um einen innovativen, sicheren, schnellen und vielfältig einsetzbaren
                  Webauftritt geht!
                </p>
              </TrailAnimation>
            </div>
          </div>
          <div>
            <div className={styles.imgBreakline6}>
              <img src={Vector6} alt="" />
            </div>
          </div>
        </TwoColumnSection>
        <Container className={styles.mapContainer}>
          <Img fluid={imgMap.childImageSharp.fluid} className={styles.map} alt="Weltkarte" />
        </Container>
        <Container className={styles.legendIconList}>
          <IconList items={legends} />
        </Container>
        <Cta1to1Meeting
          text="Machen Sie mit uns gemeinsam den ersten Schritt in eine sichere und schnellere Zukunft Ihrer Webseite und stellen Sie noch heute eine kostenlose Projektanfrage"
          link="/projektanfrage/#inquiry-header"
          btnText="Projektanfrage stellen!"
        />
      </div>
    </LayoutContent>
  );
};

export default ServicePage;
